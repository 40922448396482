.btn {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  color: $tan-light;
  border: none;
  border-radius: 0;
  padding: 16px 20px 14px;
  transition: $base-transition;
  @include letter-spacing(50);
  line-height: 1;
}

.woocommerce {
  #respond input#submit,
  a.button,
  button.button,
  input.button {
    @extend .btn;
    color: $gray-darker;

    &:disabled, &.disabled, &:disabled[disabled] {
      padding: 15px 20px;
    }
  }
}

input[type=submit]{
  cursor: pointer;
}


.woocommerce-page {
  .single_add_to_cart_button {
    text-transform: none;
    padding: 15px 20px;
    cursor: pointer;
    width: 165px;
  }
  // table {
  //  @extend .table;
  // }
}
