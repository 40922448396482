.hero {
  background-image: url("../images/hero-mobile.jpg");
  background-repeat: no-repeat;
  background-position: center;
  height: 580px;
  padding-top: 60px;
  @include phablet {
    background-image: url("../images/hero-home.jpg");
    height: 608px;
    padding-top: 80px;
  }
  @include tablet {
    padding-top: 100px;
  }
}

.cta-content {
  text-align: center;

  .value-prop {
    text-transform: uppercase;
    font-family: $secondary-font;
    margin-bottom: 20px;

    span {
      display: block;
    }

    .pre-text {
      margin-bottom: 8px;
    }

    .sub-text {
      margin: 0 auto;
      max-width: 245px;
      @include phablet {
        max-width: 100%;
      }
    }

    .pre-text,
    .sub-text {
      color: $red;
      font-size: 22px;
      @include phablet {
        font-size: 24px;
      }
      @include tablet {
        font-size: 28px;
      }
      @include laptop {
        font-size: 30px;
      }
    }

    .main {
      color: $green-text;
      font-size: 40px;
      line-height: 1;
      @include phablet {
        font-size: 40px;
      }
      @include laptop {
        font-size: 50px;
      }
      @include desktop {
        font-size: 60px;
      }
    }
  }

  .btn-row {
    margin-bottom: -15px;
    @include phablet {
      margin-bottom: 0;
    }

    .btn {
      margin: 0 auto 15px;
      width: 190px;
      display: block;
      @include phablet {
        margin: 0 10px;
        display: inline-block;
      }
    }
  }
}

.strapline-panel {
  background: linear-gradient(to bottom, rgba($red-dark, 0.7) 0%, rgba($red-dark, 0.9) 100%), url("../images/bkg-wood.jpg");
  background-repeat: repeat-y;
  background-position: center;
  position: relative;
  text-align: center;
  padding: $section-padding 0;
  @include tablet {
    padding: ($section-padding + 10px) 0;
  }
  @include laptop {
    padding: ($section-padding + 15px) 0;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 10px;
    display: block;
    left: 0;
    right: 0;
  }

  &:before {
    top: 0;
    background-image: url("../images/wooden-border-top.png");
    background-repeat: repeat-x;
    background-position: center;
  }

  &:after {
    bottom: 0;
    background-image: url("../images/wooden-border-bottom.png");
    background-repeat: repeat-x;
    background-position: center;
  }

  p {
    color: $tan-light;
    line-height: 1.5;
    @include letter-spacing(50);
    margin: 0;
    @include hd {
      padding: 0 30px;
    }
  }

  h3 {
    color: $tan-light;
    margin: 20px 0 0;
  }
}

.identity-panel {
  .card {
    h3 {
      text-align: center;
      margin: 0 0 5px;
      @include desktop {
        height: 53px;
      }
      @include wide {
        height: 83px;
      }
      @include hd {
        height: auto;
      }
    }
  }
}

.home-products-panel {
  .row {
    justify-content: space-between;
    align-items: center;
    @include laptop {
      flex-wrap: nowrap;
    }
  }

  .section-header {
    margin: 0 0 15px;
  }

  p {
    margin: 0 0 25px;
  }

  .btn {
    margin-bottom: 20px;
  }

  .content-col {
    @include laptop {
      order: 1;
    }
  }

  .panel-image {
    padding: 0 15px;
    margin-bottom: 30px;
    @include laptop {
      margin-left: -40px;
      margin-top: -25px;
      margin-bottom: -25px;
    }
  }

  .right-image {
    @include laptop {
      order: 2;
    }
  }
}

.testimonials-panel {
  overflow: hidden;
  text-align: center;
}

.slick-slider.testimonials-carousel {
  margin: 0 -15px 20px;
  @include tablet {
    margin: 0 -25px 20px;
  }
  @include wide {
    margin: 0 -45px 20px;
  }

  .slick-slide {
    margin: 0 15px;
    @include tablet {
      margin: 0 25px;
    }
    @include wide {
      margin: 0 60px;
    }

    .author {
      font-weight: 600;
      margin: 0;
      color: $red;
    }
  }
}

.slider-nav {
  position: relative;
  display: inline-block;
}

.slick-arrow {
  height: 32px;
  top: 65%;

  &:before {
    font-family: FontAwesome;
    color: $green-text;
    font-size: 30px;
    opacity: 1;
  }

  &.slick-prev {
    left: -30px;

    &:before {
      content: '\f053';
    }
  }

  &.slick-next {
    right: -30px;

    &:before {
      content: '\f054';
    }
  }
}

.slick-dots {
  position: static;
  width: auto;

  li {
    margin: 0 8px;

    button {
      &:before {
        content: '\f0a3';
        font-family: FontAwesome;
        font-size: 18px;
        color: $red;
        opacity: 1;
      }
    }

    &.slick-active button::before {
      color: $green-text;
    }
  }
}
