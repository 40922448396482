.form-inline {
  max-width: 100%;
}

.form-control {
  color: $gray;
  border-radius: 0;
  font-size: 15px;
  border: 2px solid $gray-light;
  height: 42px;
  // max-width: 100%;
  width: 100%;
  padding: 10px;
  border: 1px solid $gray-light;
  width: 100%;
  font-size: 16px;
}

textarea.form-control {
  height: auto;
}

.woocommerce {
  .woocommerce-billing-fields__field-wrapper,
  .woocommerce-address-fields__field-wrapper,
  .woocommerce-EditAccountForm {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    fieldset {
      @include flex(0, 0, 100%);
      padding: 0 10px;
      margin: 0 -10px;

      legend {
        padding: 0 10px;
      }
    }

    .select-wrapper {
      width: 100%;
    }

    .form-row {
      @include flex(0, 0, 100%);
      padding: 5px 10px;
      align-items: flex-end;
      width: auto;
      float: none;
      @include mobile {
        @include flex(0, 0, 50%);
      }

      &:before, &:after {
        content: none;
      }
    }

    > .woo-form-footer {
      padding: 0 10px;
    }
  }

  form.checkout_coupon,
  form.login,
  form.register {
    border: none;
    padding: 0;
    margin: 15px 0;
  }

  form.checkout_coupon {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p:not(.form-row) {
      width: 100%;
    }

    .form-row {
      width: auto;
      float: none;
    }

    input.input-text {
      height: 47px;
    }

    input.button {
      text-transform: none;
      font-size: 15px;
      padding: 12px 15px;
    }
  }

}

.woocommerce form {

  .form-row {
    label {
      line-height: 1.3;
      width: 100%;
    }

    input.input-text,
    textarea {
      @extend .form-control;
    }

    .select2-container,
    .select2-container--default {
      height: 42px;

      .select2-selection--single {
        border-radius: 0;
        border: 1px solid $gray-light;
        height: 42px;

        .select2-selection__arrow {
          bottom: 1px;
          height: auto;
        }

        .select2-selection__rendered {
          padding-left: 9px;
          padding-top: 9px;
          padding-bottom: 10px;
          line-height: 1.3;
        }
      }
    }
  }

  .woo-form-footer {
    margin-top: 20px;
  }

}

.select2-dropdown {
  border: 1px solid $gray-light;
  border-radius: 0;
}

// Search form
.search-form {
  @extend .form-inline;
}

.search-form label {
  font-weight: normal;
  @extend .form-group;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.aws-container {
  @extend .form-inline;

  .aws-search-field {
    @extend .form-control;
  }
}

.select-wrapper {
  @extend .form-inline;
  position: relative;

  &:before {
    content: '\f0d7';
    font-family: FontAwesome;
    color: $gray;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 20px;
    pointer-events: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    @extend .form-control;
    width: 100%;
  }
}

.gform_wrapper {
  ul {
    list-style: none;
    padding: 0;
  }

  .gfield {
    margin-bottom: 15px;

    label {
      font-weight: 600;
      color: $red;
      // text-transform: uppercase;
    }

    input,
    textarea {
      padding: 10px;
      border: 1px solid $gray-light;
      width: 100%;
      font-size: 16px;
    }

    textarea {
      height: 180px;
    }
  }

  .gform_footer {
    input[type=submit] {
      -webkit-appearance: none;
      -moz-appearance: none;
      @extend .btn;
      @extend .btn-primary;
      cursor: pointer;
    }
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}
