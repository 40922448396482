html {
  display: flex;
}

body.sideNavBody { // any body class found on every page can be used here, this keeps styles from effecting the Wordpress editor
  min-height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  max-width: 1920px;
  margin: 0 auto;
}

.wrap {
  flex: 1 1 auto;
}

html, body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  @include smooth-fonts;
  line-height: 1.1;
}

img {
  max-width: 100%;
  height: auto;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: $h1-size;
  @include tablet {
    font-size: ($h1-size + 4px);
  }
  @include laptop {
    font-size: ($h1-size + 6px);
  }
  @include wide {
    font-size: ($h1-size + 8px);
  }
}

h2, .h2 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h2-weight;
  font-size: $h2-size;
  @include tablet {
    font-size: ($h2-size + 3px);
  }
  @include laptop {
    font-size: ($h2-size + 5px);
  }
  @include wide {
    font-size: ($h2-size + 7px);
  }
}

h3, .h3 {
  color: $red;
  font-family: $body-font;
  font-weight: $h3-weight;
  font-size: $h3-size;
  @include tablet {
    font-size: ($h3-size + 2px);
  }
  @include laptop {
    font-size: ($h3-size + 4px);
  }
  @include wide {
    font-size: ($h3-size + 5px);
  }
}

.section-header {
  text-transform: uppercase;

  .pre-text {
    font-size: 20px;
    color: $red;
    display: block;
    margin-bottom: 8px;
    @include tablet {
      font-size: 18px;
    }
    @include laptop {
      font-size: 20px;
    }
  }

  .main {
    display: block;
    @extend .h1;
  }

  &.panel-header {
    text-align: center;
    margin-bottom: 20px;
    @include tablet {
      margin-bottom: 35px;
    }
    @include laptop {
      margin-bottom: 40px;
    }
    @include wide {
      margin-bottom: 45px;
    }

    .pre-text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 20px;
      @include tablet {
        font-size: 18px;
      }
      @include laptop {
        font-size: 20px;
      }

      &:before, &:after {
        margin-bottom: 5px;
      }

      &:before {
        margin-right: 20px;
        @include tablet {
          content: url(../images/header-left.png);
        }
      }

      &:after {
        margin-left: 20px;
        @include tablet {
          content: url(../images/header-right.png);
        }
      }
    }
  }
}

p, li {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: 1.3;
}

a {
  transition: $base-transition;
  &:hover, &:focus {
    text-decoration: none;
  }
}

ul.nav {
  list-style-type: none;
  padding: 0;

  .sub-menu {
    list-style-type: none;
    padding: 0;
  }
}

hr {
  width: 100%;
}

.panel {
  padding: $section-padding 0;
  @include tablet {
    padding: ($section-padding + 15px) 0;
  }
  @include laptop {
    padding: ($section-padding + 25px) 0;
  }

  &.panel-light, &.panel-dark {
    background-image: url(../images/panel-bkg.jpg);
    background-repeat: repeat;
    position: relative;
    overflow: hidden;

    .panel-content {
      position: relative;
      z-index: 300;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      z-index: 200;
      top: 0;
      bottom: 0;
      width: 180px;
    }

    &:before {
      background-image: url(../images/site-edge-left.png);
      background-repeat: repeat-y;
      left: -30px;
      @include tablet {
        left: -20px;
      }
      @include laptop {
        left: 0;
      }
    }

    &:after {
      background-image: url(../images/site-edge-right.png);
      background-repeat: repeat-y;
      right: -30px;
      @include tablet {
        right: -20px;
      }
      @include laptop {
        right: 0;
      }
    }
  }

  &.panel-dark {
    background-image: url(../images/panel-bkg-dark.jpg);
    background-repeat: repeat;
  }
}

.panel-footer {
  text-align: center;
  @extend .panel;
  padding-bottom: 0;
}

.card-row {
  margin-bottom: -30px;
}

.card-col {
  margin-bottom: 30px;
}

.card {
  border: none;
  border-radius: 0;
  padding: 10px;
  box-shadow: $box-shadow;
  background-color: $gray-lighter;
  // height: 100%;

  &.height-auto {
    height: auto;
  }

  .card-body {
    padding: 15px;
    border: 1px solid $gray-light;
    display: flex;
    flex-direction: column;
    @include tablet {
      padding: 20px;
    }
  }

  .card-image {
    // margin-top: auto;

    &:before, &:after {
      content: '';
      background-image: url(../images/card-border.png);
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      height: 30px;
    }

    img {
      width: 100%;
    }
  }

  .card-content p {
    margin: 0;
  }
}

.header-phone {
  display: none;
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-item {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}
