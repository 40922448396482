.woocommerce-order {

  h2 {
    margin: 0 0 15px;
  }

  ul.order_details {
    padding-left: 0;
    margin-bottom: 30px;

    li {
      border-right: none;
      margin-bottom: 3px;
      @include tablet {
        border-right: 1px dashed #d3ced2;
        margin-bottom: 15px;
      }

      strong {
        font-size: 1.1em;
        @include mobile {
          font-size: 1.4em;
        }
      }
    }
  }

}

.woocommerce {
  .woocommerce-customer-details address {
    border: none;
    border-radius: 0;
    padding: 0;

    .woocommerce-customer-details--phone {
      &:before {
        font-family: FontAwesome;
        content: '\f095';
        line-height: 1.6;
      }
    }

    .woocommerce-customer-details--email {
      &:before {
        font-family: FontAwesome;
        content: '\f0e0';
        line-height: 1.6;
      }
    }
  }

  .woocommerce-form-login {
    // margin-bottom: 20px;
    border-radius: 0!important;

    .card {
      box-shadow: none;
      max-width: 500px;
    }
  }
}

.woocommerce-account {
  .woocommerce-MyAccount-navigation,
  .woocommerce-MyAccount-content {
    width: 100%;
    float: none;
  }

  .woocommerce-MyAccount-navigation {

    .card-body {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    ul {
      @extend .nav;
      flex-direction: column;
      margin: 0;
      li {
        border-bottom: 1px solid $gray-light;

        &.is-active {
          a {
            background-color: $red;
            color: $white;
          }
        }

        a {
          display: block;
          padding: 16px 20px 14px;
          font-family: $secondary-font;
          text-transform: uppercase;
          transition: $base-transition;
          color: $red;

          &:hover, &:focus {
            background-color: $red;
            color: $white;
          }

          &:before {
            font-family: FontAwesome;
            padding-right: 15px;
          }
        }

        &:last-of-type {
          border-bottom: 0;
        }
      }

      li.woocommerce-MyAccount-navigation-link- {
        &-dashboard a::before {
          content: '\f0e4';
        }
        &-orders a::before {
          content: '\f022';
        }
        &-downloads a::before {
          content: '\f019';
        }
        &-edit-address a::before {
          content: '\f2b9';
        }
        &-edit-account a::before {
          content: '\f2be';
        }
        &-customer-logout a::before {
          content: '\f08b';
        }
      }
    }
  }

  .woocommerce-orders-table__row {
    a.button {
      text-transform: none;
      font-size: 15px;
      padding: 10px 18px;
    }
  }
}
