.header-banner {
  position: relative;
  z-index: 1001;
  margin-bottom: -60px;
  @include phablet {
    margin-bottom: -50px;
  }

  .nav li {
    a {
      position: relative;
      display: block;

      &:after {
        content: '';
        position: absolute;
        transform: translateX(-50%);
        transition: $base-transition;
        background-color: $tan;
        visibility: hidden;
        display: block;
        height: 1px;
        width: 0;
        left: 50%;
        top: 100%;
      }

      &:hover, &:focus {
        &:after {
          visibility: visible;
          width: 100%;
        }
      }
    }

    &.active a:after {
      visibility: visible;
      width: 100%;
    }

    &.header-phone {
      display: block;
      a:after {
        content: none;
      }
    }
  }
}

.header-top {
  background-image: url(../images/bkg-wood.jpg);
  background-repeat: repeat-y;
  background-position: center;
}

.header-top-content {
  padding: 20px 0 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include tablet {
    padding: 20px 0 45px;
  }
  @include desktop {
    justify-content: space-between;
  }

  .nav {
    margin: 0 -10px;
    li {
      padding: 0 10px;
      a {
        text-transform: uppercase;
        font-size: 15px;
        padding: 2px 0;
        color: $tan;
        display: block;
      }
    }
  }

}

.header-main {
  height: 110px;
  background-image: url(../images/header-mobile.png);
  background-repeat: repeat-x;
  background-position: center;
  background-size: auto 80%;
  position: relative;
  top: -25px;
  @include tablet {
    background-size: auto;
  }
  @include desktop {
    height: 125px;
    background-image: url(../images/header-full.png);
    background-repeat: no-repeat;
    background-position: center;
  }

  .brand {
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 25%;
    top: 50%;
    width: 140px;
    @include tablet {
      width: auto;
      left: 20%;
    }
    @include desktop {
      top: 45%;
      left: 50%;
    }
  }

  .hamburger {
    margin-left: auto;
  }
}

.header-main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  height: 110px;

  .nav {
    margin: 0 -17px;
    @include wide {
      margin: 0 -20px;
    }
    @include hd {
      margin: 0 -25px;
    }
    li {
      position: relative;
      padding: 0 17px;
      @include wide {
        padding: 0 20px;
      }
      @include hd {
        padding: 0 25px;
      }

      &:last-of-type:after {
        content: none;
      }

      &:after {
        content: '\f005';
        font-family: FontAwesome;
        color: $yellow;
        font-size: 10px;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 100%;
        top: 45%;
      }

      a {
        font-family: $secondary-font;
        text-transform: uppercase;
        @include letter-spacing(25);
        display: block;
        color: $red;
        font-size: 16px;
        @include wide {
          font-size: 20px;
        }

        &:after {
          background-color: $red;
          height: 1px;
        }
      }
    }
  }

  .nav-right {

  }
}
