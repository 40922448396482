.woocommerce, .woocommerce-page {
  .woocommerce-message,
  .woocommerce-error,
  .woocommerce-info, {
    border: 1px solid $gray-light;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 60px;
    padding-right: 40px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    @include tablet {
      flex-wrap: nowrap;
    }

    &:after {
      display: block;
      order: 1;
      flex-basis: 100%;
      @include tablet {
        content: none;
      }
    }

    a:not(.button) {
      order: 2;
      @include tablet {
        margin-left: auto;
        padding-left: 10px;
      }
    }

    a.button {
      order: 2;
      float: none;
      margin-top: 10px;
      @extend .btn-primary;
      padding-top: 13px;
      padding-bottom: 12px;
      width: auto;
      text-transform: none;
      @include tablet {
        margin-top: 0;
        margin-left: auto;
      }
    }
  }

  .woocommerce-message {
    border-color: $brand-primary;
    &:before {
      color: $brand-primary;
    }
  }

  .woocommerce-info {
    border-color: #1e85be;
  }

  .woocommerce-error {
    border-color: $red;
    &:before {
      color: $red;
    }
  }

  nav.woocommerce-pagination {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;

      li {
        border: none;
        float: none;

        .page-numbers {
          &:hover, &:focus, &.current {
            background-color: transparent;
            color: $gray-darker;
          }

          &.prev, &.next {
            font-size: 20px;
          }

        }
      }
    }
  }

}

.shop-sidebar-col {
  @include wide {
    @include flex(0, 0, 20%);
  }
}

.shop-main-col {
  @include wide {
    @include flex(0, 0, 80%);
  }
}

.store-notice {
  @include flex(0, 0, 100%);
  margin-bottom: 20px;

  p {
    padding: 10px;
    margin: 0;
    background-color: rgba($tan, 0.4);
    border: 1px solid $tan;
    color: $gray-dark;
    font-size: 16px;
  }
}

.card.pre-shop-loop {
  box-shadow: none;
  margin-bottom: 40px;

  .card-body {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 15px;
    @include tablet {
      padding: 20px 30px;
    }

    > nav, > p, > div, > form {
      padding-left: 5px;
      padding-right: 5px;
      font-size: 15px;
      color: $gray;
    }
  }

  .form-control {
    height: 39px!important;
    padding: 7px 10px;
    @include laptop {
      width: 271px;
    }
  }

  .woocommerce-result-count {
    @media screen and (max-width: $width_tablet - 1px) {
      @include flex(0, 0, 100%);
    }
  }

  .woocommerce-breadcrumb {
    @include flex(0, 0, 100%);
    padding-left: 5px;
    padding-right: 5px;
    line-height: 1.4;

    a {
      color: $gray-darker;
      text-decoration: underline;
    }

    .fa {
      color: $gray-darker;
      margin: 0 7px;
      @include tablet {
        margin-right: 20px;
      }
    }
  }

  .woocommerce-ordering, .aws-container {
    @include flex(0, 0, 100%);
    @include mobile {
      @include flex(0, 0, 50%);
    }
    @include tablet {
      flex: 0 0 auto;
      max-width: 100%;
    }
  }

  .woocommerce-ordering {
    margin: 0 0 15px auto;
    max-width: 100%;
    @include mobile {
      margin-bottom: 0;
    }
  }

  &.product-page .woocommerce-breadcrumb {
    margin: 0;
  }
}

.product-category.card {
  img {
    width: 100%;
  }

  .woocommerce-loop-category__title {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0;

    mark.count {
      padding: 0 0 0 10px;
      background-color: transparent;
    }
  }
}
