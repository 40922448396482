.footer-cta-panel {
  background-image: url("../images/bkg-mobile.jpg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 0;
  @include tablet {
    background-image: url("../images/bkg-footer-cta.jpg");
    background-position: center;
    padding-top: 75px;
    height: 649px;
  }
}

.footer-main {
  background: linear-gradient(to bottom, rgba($red-dark, 0.4) 0%, rgba($red-dark, 0.9) 100%), url("../images/bkg-wood.jpg");
  background-repeat: repeat-y;
  background-position: center;
  padding: ($section-padding + 10px) 0 $section-padding;
  position: relative;
  @include tablet {
    padding: ($section-padding + 10px) 0 ($section-padding + 5px);
  }
  @include laptop {
    padding: ($section-padding + 15px) 0 ($section-padding + 10px);
  }

  &:before {
    content: '';
    background-image: url("../images/wooden-border-top.png");
    background-repeat: repeat-x;
    background-position: center;
    position: absolute;
    display: block;
    width: 100%;
    height: 10px;
    left: 0;
    right: 0;
    top: 0;
  }

  a,
  p {
    color: $tan-light;
    line-height: 1.5;
  }

  a {
    &:focus,
    &:hover {
      color: #ffae00;
    }
  }

  .col {
    @media screen and (max-width: $width_tablet - 1px) {
       @include flex(0, 0, 100%);
       margin-bottom: 20px;
       text-align: center;
       &.nav-col {
         @include flex(0, 0, calc(100%  + 30px));
       }
    }
  }

  .contact-col {
    p {
      white-space: nowrap;
    }
  }

  .logo-col {
    @include wide {
       @include flex(0, 0, 360px);
    }
  }

  .hours-col {
    p {
      margin: 0;
      white-space: nowrap;
    }

    .hours-title {
      font-weight: 600;
      text-transform: uppercase;
    }

    a {
      color: #ffae00;
      text-decoration: underline;

      &:focus,
      &:hover {
        color: $tan-light;
      }
    }
  }

  .nav-col {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
    flex-wrap: wrap;
    @media screen and (min-width: $width_tablet) and (max-width: $width_laptop - 1px) {
       @include flex(0, 0, 100%);
       margin-top: 20px;
    }
    @include laptop {
      flex-wrap: nowrap;
    }
    @include wide {
       @include flex(0, 0, 450px);
    }

    nav {
      padding: 0 15px;
      @media screen and (max-width: $width_laptop - 1px) {
         @include flex(0, 0, 100%);
      }
    }

    .nav {
      flex-direction: column;
      @include tablet {
        flex-direction: row;
        justify-content: center;
      }
      @include laptop {
        flex-direction: column;
        justify-content: flex-start;
      }
    }

    a {
      white-space: nowrap;
      display: block;
      @media screen and (min-width: $width_tablet) and (max-width: $width_laptop - 1px) {
        padding: 4px 10px;
      }
    }
  }
}

.copyright {
  background-color: #34160f;
  padding: 10px 0;

  .copy-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    @include laptop {
      justify-content: space-between;
    }

    a,
    div {
      color: $tan-light;
      font-size: 12px;
    }
  }

  .links-copy {
    margin-bottom: 10px;
    @include tablet {
      margin-bottom: 0;
    }
  }
}
