.hero.interior-hero {
  height: 400px;
  @include laptop {
    height: 420px;
  }

  .main {
    font-size: 36px;
    @include phablet {
      font-size: 54px;
    }
    @include tablet {
      font-size: 62px;
    }
    @include laptop {
      font-size: 72px;
    }
  }
}

.page-content-panel {
  padding-top: ($section-padding + 40px);
  @include phablet {
    padding-top: ($section-padding + 35px);
  }
  @include tablet {
    padding-top: ($section-padding + 50px);
  }
  @include laptop {
    padding-top: ($section-padding + 40px);
  }
}

.panel-border-top {
  position: relative;
  // height: 10px;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 10px;
    display: block;
    right: 0;
    left: 0;
    top: 0;
    z-index: 400;
    background-image: url("../images/wooden-border-top.png");
    background-repeat: repeat-x;
    background-position: center;
  }
}

.page-content-panel, .landing-page-content-panel {
  h2 {
    margin: 0 0 20px;
  }
}

.section-header.page-header {
  text-align: center;
  margin-bottom: 20px;
  @include tablet {
    margin-bottom: 35px;
  }
  @include laptop {
    margin-bottom: 40px;
  }
  @include wide {
    margin-bottom: 45px;
  }

  .pre-text {
    text-align: center;
    font-size: 20px;
    margin-bottom: 0;
    @include tablet {
      font-size: 18px;
    }
    @include laptop {
      font-size: 20px;
    }
  }

  .main {
    display: flex;
    align-items: center;
    justify-content: center;

    &:after,
    &:before {
      margin-bottom: 20px;
    }

    &:before {
      margin-right: 20px;
      @include tablet {
        content: url("../images/header-left.png");
      }
    }

    &:after {
      margin-left: 20px;
      @include tablet {
        content: url("../images/header-right.png");
      }
    }
  }
}


.contact-map-panel {
  margin-top: auto;
}

.location-details {
  margin-top: 30px;
}

.error404-inner {
  padding-top: 75px;

  .col-404 {
    text-align: center;
    max-width: 600px;
  }
}
