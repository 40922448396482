.products.row {
  > .card-col {
    margin-bottom: 30px;
  }
}

.product {

  .woocommerce-loop-product__title {
    font-size: 18px;
    margin-bottom: 3px;
    color: $red;
  }

  .woocommerce-Price-amount {
    color: $gray-darker;
    font-size: 17px;
    font-weight: 600;
  }

  .woocommerce-LoopProduct-link {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .woocommerce-product-details__short-description {
    margin-top: auto;
    p {
      margin-bottom: 0;
      color: $gray-darker;
    }
  }

  .card-image {
    margin-top: 0;
    &:before, &:after {
      margin: 3px 0;
    }
  }

  span.onsale {
    min-width: 55px;
    min-height: 55px;
    top: -1.5em;
    left: -1.5em;
  }

}

.woocommerce-page.woocommerce {

  #content {

    div.product {

      &.single-product-card .card-body {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px 15px;
        @include laptop {
          flex-wrap: nowrap;
          padding: 30px 20px;
        }

        h1 {
          color: $red;
          font-size: 22px;
          text-transform: uppercase;
          margin: 0 0 15px;
          @include laptop {
            margin: 0 0 20px;
          }
        }

        p.price {
          margin: 0 0 15px;
          @include laptop {
            margin: 0 0 25px;
          }
        }

        div.images {
          width: 100%;
          float: none;
          @include laptop {
            padding: 0 15px;
            width: 700px;
            margin: 0;
          }
        }

        div.summary {
          flex-grow: 1;
          float: none;
          width: auto;
          margin: 0;
          @include laptop {
            padding: 0 15px;
          }
        }
      }

      .woocommerce-product-gallery__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px -5px;

        .woocommerce-product-gallery__image {
          padding: 5px;

          @include flex(0, 0, calc(100% / 3));

          @include phablet {
            @include flex(0, 0, 25%);
          }

          @include laptop {
            @include flex(0, 0, calc(100% / 3));
          }

          @include wide {
            @include flex(0, 0, 25%);
          }

          &:first-of-type {
            @include flex(0, 0, 100%);
          }
        }
      }

      .woocommerce-product-details__short-description {
        p {
          margin: 0 0 15px;
          @include laptop {
            margin: 0 0 25px;
          }
        }
      }

      .product_meta {
        margin: 0 0 30px;
      }

      .form-control, select {
        font-size: 17px;
        height: 36px!important;
        color: $black;
      }

      .form-control {
        padding: 3px 10px;
      }


      .select-wrapper {
        width: 200px;

        select {
          padding: 3px 7px;
        }

        &:before {
          right: 10px;
          font-size: 15px;
        }
      }

      form.cart {
        margin-bottom: 0;
        .variations {
          display: block;
          margin-bottom: 10px;

          tbody, tr, td, th {
            line-height: 1.1;
            display: block;
          }

          tbody tr {
            padding-bottom: 10px;
          }

          td.value {
            display: flex;
            align-items: center;
          }

          label {
            font-weight: 400;
          }

          select {
            margin-right: 0;
          }

          .reset_variations {
            display: none;
            margin-left: 8px;
          }
        }

      }

      div.quantity {
        float: none;
        margin-bottom: 20px;
        @include laptop {
          margin-bottom: 40px;
        }
      }

      .woocommerce-variation-price {
        margin-bottom: 20px;
      }

      input.qty {
        @extend .form-control;
        text-align: left;
      }

    }

  }

}

.products.related {
  > h2 {
    margin: 50px 0 30px;
  }
}

.quantity-wrapper {
  position: relative;
  width: 200px;

  &:after {
    content: '';
    position: absolute;
    z-index: 100;
    top: 2px;
    right: 2px;
    bottom: 2px;
    width: 30px;
    background-color: $white;
    font-size: 15px;
  }

  #add, #subtract {
    position: absolute;
    transform: translateY(-50%);
    z-index: 200;
    right: 10px;
    color: $gray;
    cursor: pointer;

    &:before {
      font-family: FontAwesome;
      font-size: 15px;
      width: 10px;
      height: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  #add {
    top: calc(50% - 7px);
    &:before {
      content: '\f0d8';
    }
  }

  #subtract {
    top: calc(50% + 7px);
    &:before {
      content: '\f0d7';
    }
  }
}

input.qty {
  @extend .form-control;
  text-align: left;
}
