aside.sidebar-card {
  height: auto;
  margin-bottom: 30px;
  @include laptop {
    margin-bottom: 0;
  }

  .card-body {
    padding: 0;
  }

  h3 {
    background-color: $red-dark;
    font-family: $secondary-font;
    text-transform: uppercase;
    color: $gray-lighter;
    padding: 22px 15px 18px;
    line-height: 1;
    font-size: 18px;
  }

  .widget_product_categories {
    h3 {
      margin: 0;
    }
  }

  .product-categories {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      padding: 10px 20px;
      border-bottom: 1px solid $gray-darker;
      &:last-of-type {
        border: none;
      }
    }

    a {
      color: $gray-dark;
      display: block;
    }

    .current-cat-parent > a {
      color: $gray-darker;
      font-weight: 600;
    }

    .current-cat > a,
    .current-cat > ul > .top-level-cat > a {
      color: $gray-darker;
      font-weight: 600;
    }

    .cat-parent {
      .current-cat {
        color: $gray-darker;
      }

      .cat-parent > a {
        padding-left: 16px;

        &::before {
          top: 13px;
        }
      }

      > a {
        padding-left: 20px;
        position: relative;

        &:before {
          content: '\f0da';
          font-family: FontAwesome;
          transform: translate(-50%, -50%) rotate(0);
          position: absolute;
          top: 11px;
          left: 6px;
        }
      }

      &.open > a:before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }


    .children {
      list-style-type: none;
      padding-left: 30px;
      will-change: height;
      margin: 4px 0 2px;

      .current-cat > a,
      .current-cat > ul > .top-level-cat > a {
        color: $gray-darker;
        font-weight: 600;
      }

      a {
        padding: 2px 0;
      }
    }

  }

  .widget_price_filter {
    .price_slider_wrapper {
      padding: 20px;

      .ui-slider {
        background-color: $gray-darker;
        margin-bottom: 25px;

        .ui-slider-handle {
          background-color: $gray-light;
          border: 1px solid $brand-primary;
          top: calc(50% - 1px);
          transform: translateY(-50%);
        }

        .ui-slider-range {
          background-color: $brand-primary;
        }
      }

      button.button {
        text-transform: none;
        padding: 12px 20px;
      }
    }
  }
}
