$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

// Colors
$white:                 #ffffff;
$gray-100:              #f8f9fa;
$gray-600:              #868e96;
$gray-800:              #343a40;
$green:                 #28a745;
$cyan:                  #17a2b8;

$brand-primary:         #71772c;
$green-text:            #5d630e;
$red:                   #6d2900;
$red-dark:              #391a11;
$yellow:                #dea800;
$tan-light:             #f4eddf;
$tan:                   #d6caaf;
$gray-lighter:          #fdfbf8;
$gray-light:            #f1efeb;
$gray:                  #848484;
$gray-dark:             #5c5a58;
$gray-darker:           #313131;

$theme-colors: (
  primary:              $brand-primary,
  secondary:            $gray,
  success:              $green,
  info:                 $cyan,
  warning:              $yellow,
  danger:               $red,
  light:                $gray-lighter,
  dark:                 $gray-darker
);                               // small, breadcrumbs etc

// Fonts
$primary-font:          "canada-type-gibson", sans-serif;
// Regular              400
$secondary-font:        "abril-fatface", serif;
// Regular              400
// SemiBold             600
// Bold                 700
$body-font-size:        17px;
$body-font-weight:      400;
$body-font:             $primary-font;
$body-color:            $gray-darker;
$heading-font:          $secondary-font;
$heading-color:         $brand-primary;
$h1-weight:             400;
$h1-size:               32px;
$h2-weight:             400;
$h2-size:               25px;
$h3-weight:             600;
$h3-size:               20px;

// Base Styles
$base-transition:       0.3s ease-in-out;
$section-padding:       30px;
$box-shadow:            0px 10px 17px 0px rgba(0, 0, 0, 0.2);

//Side-Nav
$sideNavWidth: 260px;
$sideNavDirection: (right);

$button_width: 40px;     // width of button area
$button_height: 60px;     // height of button area
$bar_thickness: 6px;     // thickness of button bars
$bar_roundness: 6px;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $brand-primary;
$hamburger_active: $brand-primary;


// Bootstrap Variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  wd: 1440px,
  hd: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  wd: 1400px,
  hd: 1560px
);

// Breakpoint variables
$width_mobile:          480px;
$width_phablet:         576px;
$width_tablet:          768px;
$width_laptop:          992px;
$width_desktop:         1200px;
$width_wide:            1440px;
$width_hd:              1600px;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
@mixin wide { @media (min-width: #{$width_wide}) { @content; } }
@mixin hd { @media (min-width: #{$width_hd}) { @content; } }

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin stretch($offset: 0) {
  position: absolute;
  top: #{$offset};
  right: #{$offset};
  left: #{$offset};
  bottom: #{$offset};
  z-index: 99;
}

@mixin smooth-fonts() {
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

@mixin letter-spacing($letter-spacing: 0) {
  letter-spacing: calc(#{$letter-spacing} * 0.001em);
}

// Flexbox mixin - prevents most IE 11 issues
@mixin flex($grow: 0, $shrink: 0, $basis: 100%) {
  flex: #{$grow} #{$shrink} #{$basis};
  max-width: #{$basis};
}
