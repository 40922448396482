.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: $brand-primary;
    transition: all 0.3s ease;
    overflow-y: auto;
    box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4);

    a {
      display: block;
      color: $tan-light;
      font-size: 16px;
      font-weight: 600;
      padding: 12px 20px;
    }

    .nav:first-of-type {
      margin-top: 5px;
    }

    .nav:last-of-type {
      margin-bottom: 10px;
    }

    .account-nav {
      padding-top: 10px;
      border-top: 1px solid rgba($tan-light, 0.6);
      // background-color: rgba($gray-darker, 0.4);
      margin: 10px 20px 0;

      a {
        padding-left: 0;
        padding-right: 0;
      }

    }

    &.sideNav-open {
      #{$sideNavDirection}: 0px;
      @include desktop {
        #{$sideNavDirection}: -$sideNavWidth;
      }
    }
}

/* classes applied to the body */
.sideNavBody {
  overflow-x: hidden;
  position: relative;
  #{$sideNavDirection}: 0;
  transition: all 0.3s ease;
}

.sideNavBody-push {
  #{$sideNavDirection}: $sideNavWidth;
  @include desktop {
    #{$sideNavDirection}: 0;
  }
}
