.gallery-card {
  margin-top: 30px;

  @include desktop {
    margin-top: 0;
  }

  .page-gallery .gallery-col {
    @include wide {
       @include flex(0, 0, 25%);
    }
  }
}

.gallery-card-wide .page-gallery .gallery-col {
  @include laptop {
     @include flex(0, 0, 25%);
  }

  @include wide {
    @include flex(0, 0, 20%);
  }
}

.page-gallery {

  .gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px -10px;
  }

  .gallery-col {
    display: block;
    padding: 0 5px;
    margin-bottom: 10px;

    @include flex(0, 0, 50%);

    @include tablet {
       @include flex(0, 0, calc(1 / 3 * 100%));
    }

    img {
      width: 100%;
    }
  }

  .gallery-image {
    position: relative;
    display: block;

    &.hide-image {
      display: none;
    }

    &:after {
      content: '';
      @include stretch();
      background-color: rgba(0, 0, 0, 0.7);
      transition: $base-transition;
      will-change: opacity;
      visibility: hidden;
      opacity: 0;
    }

    .fa {
      transition: $base-transition;
      position: absolute;
      z-index: 300;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      font-size: 25px;
      color: $white;
      will-change: opacity;
      visibility: hidden;
      opacity: 0;
    }

    &:focus,
    &:hover {
       &:after,
      .fa {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .open-gallery {
    @include aspect-ratio(1, 1);
    background-color: $brand-primary;
    transition: $base-transition;
    will-change: background-color;
    display: block;

    span {
      position: absolute;
      z-index: 300;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      font-size: 25px;
      color: $white;
      font-weight: 500;

      small {
        display: block;
      }
    }

    &:focus,
    &:hover {
      background-color: $black;
    }
  }
}

.lg-object.lg-image {
  max-height: 85vh;
}
