.woocommerce,
.woocommerce-page {
  .woocommerce-input-wrapper {
    width: 100%;
  }

  .cart-collaterals {
    .cart_totals {
      width: 100%;
      float: none;
    }
  }

  table.cart {
    img {
      width: 60px;
    }
  }

  table.shop_table {
    margin-bottom: 0;
    border-radius: 0;
    border: none;

    tr.cart_item, tr.order_item {
      td.product-name {
        strong {
          display: block;
          @include tablet {
            display: inline;
          }
        }
      }
    }

    thead {
      border-bottom: 1px solid $gray-light;
    }

    tfoot th, thead th {
      padding: 9px 0;
      @include tablet {
        padding: 9px 12px;
      }
    }

    td {
      border: none;
      padding: 6px 0;
      @include tablet {
        padding: 6px 12px;
      }

      &.actions {
        margin-top: 15px;
        padding: 10px 12px;
        background-color: $gray-light!important;
        @include tablet {
          padding-top: 35px;
          padding-bottom: 6px;
          background-color: transparent!important;
        }

        .coupon {
          input.button {
            width: 100%;
            float: none;
            @include tablet {
              width: auto;
            }
          }
          .input-text {
            width: 100%;
            margin: 0 0 5px;
            max-width: 100%;
            height: 39px;
            @include tablet {
              width: auto;
              margin-right: 10px;
            }
          }
        }

        input[name="update_cart"] {
          @extend .btn-primary;
        }
      }
    }

    .quantity-wrapper {
      width: 80px;

    }

    .quantity {
      label {
        @extend .sr-only;
      }
    }

    input.qty {
      @extend .form-control;
      text-align: left;
    }
  }

  table.shop_table_responsive {
    border-spacing: 0;

    tr {
      border-top: 1px solid $gray-light;

      &.cart_item {
        padding: 10px 0;
        @include tablet {
          padding: 0;
        }
      }

      &:first-of-type {
        border-top: none;
      }

      &:nth-child(2n) {

        td {
          background-color: transparent;
          @media screen and (max-width: $width_tablet - 1px) {
            border: none;
          }
        }
      }

      td {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: left!important;
        @include mobile {
          flex-wrap: nowrap;
          text-align: right!important;
        }
        @include tablet {
          display: table-cell;
          text-align: left!important;
        }

        &:before {
          @include flex(0, 0, 100%);
          padding-bottom: 6px;
          @include mobile {
            padding-bottom: 0;
            flex: 0 0 auto;
          }
        }

        &.actions {
          display: block;
          @include tablet {
            display: table-cell;
            text-align: right!important;
          }
        }

        &:before {
          float: none;
        }
      }

    }
  }

  table.cart {
    input.button {
      text-transform: none;
      font-size: 15px;
      padding: 12px 15px;

      &.disabled,
      &:disabled,
      &:disabled[disabled] {
        padding: 12px 15px;
      }
    }

    .input-text {
      width: auto;
      max-width: 100%;
    }
  }
}

.create-account {
  .form-row {
    @include tablet {
      width: calc(50% - 10px);
    }
  }
}

#add_payment_method,
.woocommerce-cart,
.woocommerce-checkout {
  .wc-proceed-to-checkout {
    padding-bottom: 0;

    a.checkout-button {
      margin-bottom: 0;
      font-size: 15px;
      @include mobile {
        font-size: 17px;
      }
      @include tablet {
        font-size: 1.25em;
      }
      @include laptop {
        font-size: 16px;
      }
      @include desktop {
        font-size: 1.25em;
      }
    }
  }
}


#order_review {

  #payment{
    border-radius: 0;
    .payment_box {
      border-radius: 0;
    }
  }
}
